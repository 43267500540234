.producer-page {
	padding-top: 7rem;
	padding-bottom: 3rem;

	.producer-header {
		display: flex;

		img {
			object-fit: cover;
			height: 100%;
			width: 100%;
			max-height: 100%;
			max-width: 100%;
			min-height: 100%;
			min-width: 100%;
		}

		.producer-header-img1 {
			flex: 0.5;
			margin-right: 1rem;
		}

		.producer-header-description {
			flex: 1;
		}
	}

	.producer__title {
		font-weight: 800;
		font-family: "Montserrat";
		margin: 0.5rem 0;
	}

	.producer-body {
		.project__cateogryWrapper {
			margin: 2rem 0;

			.category__title {
				font-weight: 600;
				font-family: "Montserrat";
				margin: 0.5rem 0;
			}
		}

		.cateogry__videosWrapper {
			display: grid;
			gap: 1rem;
			grid-template-columns: repeat(3, 1fr);
			margin: 1rem 0;
		}

		.cateogry__videosWrapper__showreel {
			grid-template-columns: 2fr 1fr 1fr;
		}
	}
}

.ytp-show-cards-title {
	display: none !important;
}
