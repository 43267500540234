.videoPlayer {
	flex: 1;
	min-height: 300px;
	width: 100%;
	min-width: 300px;
	position: relative;
}

.thumbnail {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

.video-player__video {
	width: 100%;
	height: 100%;
}

.video {
	height: 300px;
	width: 100%;
}

.video > div,
.video iframe {
	height: 100%;
	width: 100%;
}

.description {
	text-align: center;
	padding-top: 0.5rem;
	line-height: 23px;
}

.desc__title {
	font-weight: 600;
}
