.spinnerContainer {
	height: 100%;
	width: 100%;
}

.lds_dual_ring {
	display: inline-block;
	width: 80px;
	height: 80px;
	position: relative;
	left: calc(50% - 40px);
}
.lds_dual_ring:after {
	content: " ";
	display: block;
	width: 64px;
	height: 64px;
	margin: 8px;
	border-radius: 50%;
	border: 6px solid #fff;
	border-color: #fff transparent #fff transparent;
	animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
